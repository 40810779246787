<template>
  <div>
		<b-container
		fluid
		class="mt-3 mb-3">
      <b-row>
        <b-col>
          <b-card class="fixNotchMarginTop">
            <b-card-text>
							<i class="fa-solid fa-chalkboard-user"></i>
							<h2
                             class="noselect">{{ $t('welcome.welcome') }}</h2>
							<div
							class="m-4"
							v-html="$store.state.studentInfo">
							</div>
							<h4 aria-live="assertive"
                             class="noselect"
                             :aria-label= "$t('welcome.waiting-questions')">
                             {{ info ? info : $t('welcome.waiting-questions') }}</h4>
            </b-card-text>
					</b-card>
        </b-col>
      </b-row>
		</b-container>
       <div
    class="version-text"
    style="bottom: 5px;">
      <span class="noselect">{{ $store.state.deviceId }}</span>
    </div>
  </div>
</template>

<script>

export default {
	name: 'Welcome',
	props: ['info'],
};
</script>

<style scoped>
h2 {
    color: #D5006C;
    font-weight: 600;
}
h4 {
    font-size: 1.375rem;
    font-weight: 600;
    color: #707070;
}
.card {
    bottom: 0;
}
.card-body {
    margin: 8% auto;
    text-align: center;
    /*padding: 128px 0;*/
}
.card-body i {
    font-size: 12.5rem;
    color: #D5006C;
    margin-bottom: 20px;
}
@media only screen and (max-width: 1365px),
		only screen and (max-height: 820px) {
    .card {
    /*height: 88vh;*/
    position: fixed;
    right: 15px;
    left: 15px;
    bottom: 25px;
    top: 85px;
    margin: 0 auto;
    max-width: 100%;
    overflow: auto;
    padding-bottom: 30px;
    }
}
@media only screen and (max-height: 820px) {
    .card-body {
        padding: 60px 0;
    }
    .card-body i {
    font-size: 10.5rem;
    }
}
.version-text {
    top: 665px;
}
@media only screen and (max-width: 1365px),
	only screen and (max-height: 820px) {
    .version-text {
        top: inherit;
    }
}
</style>
