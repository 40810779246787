<template>
	<b-list-group ref="questionList" class="question-list">
		<b-list-group-item :active="index === currentQIndex"
		v-for="(qdef, index) in tdef.questions" :key="qdef.questionId"
		@click="$emit('move-to', index)">
			<!-- eslint-disable-next-line max-len -->
			{{ $store.state.mode === mode.ONLINE_TEST ? index + 1 : qdef.questionId }}. {{ qdef.questionName }}
			<span>
				{{ qdef.givenAnswer ? $t('test-navbar.answer') + getGivenAnswer(qdef) : '' }}
			</span>
		</b-list-group-item>
	</b-list-group>
</template>

<script>
import BaseComponent from '@/components/common/BaseComponent';
import { QuestionType } from '@/components/common/constants';

export default {
	name: 'QuestionList',
	mixins: [BaseComponent],
	props: ['tdef'],
	computed: {
		currentQIndex() {
			return this.$store.state.currentQIndex;
		},
	},
	methods: {
		scrollToBeVisible(ele, container) {
			const eleTop = ele.offsetTop;
			const eleBottom = eleTop + ele.clientHeight;

			const containerTop = container.scrollTop;
			const containerBottom = containerTop + container.clientHeight;

			if (eleTop < containerTop) {
				// Scroll to the top of container
				container.scrollTop -= containerTop - eleTop;
			} else if (eleBottom > containerBottom) {
				// Scroll to the bottom of container
				container.scrollTop += eleBottom - containerBottom;
			}
		},
		scrollToCurrentQDefMobile() {
			if (!this.$refs || !this.$refs.questionList) {
				return;
			}
			this.scrollToBeVisible(
				this.$refs.questionList.children[this.currentQIndex],
				this.$refs.questionList
			);
		},
		scrollToCurrentQDef() {
			if (!this.$refs || !this.$refs.questionList) {
				return;
			}
			this.scrollToBeVisible(
				this.$refs.questionList.children[this.currentQIndex],
				this.$refs.questionList.parentElement.parentElement
			);
		},
		getGivenAnswer(qdef) {
			if (qdef.questionType === QuestionType.Q_TF_TYPE) {
				const answer = qdef.answers.find((a) => a.akey === qdef.givenAnswer);
				return this.getAnswerText(answer.at);
			}
			return qdef.givenAnswer;
		}
	},
};
</script>

<style scoped>
/*.sidebar.online-test-sidebar .question-list {
    height: 634px;
    overflow: auto;
}
@media only screen and (max-width: 1365px),
       only screen and (max-height: 820px) {
           .sidebar.online-test-sidebar .question-list {
               height: calc(100vh - 150px);
           }
}*/
</style>
