<template>
  <div>
    <div
    class="help-container">
      <b-link
			:href="$store.state.helpUrl"
      :aria-label= "$t('tooltip.help')"
      target="_blank"
			v-b-tooltip.hover="{ title: $t('tooltip.help'), disabled: isMobile() }">
        <i class="fa-solid fa-circle-question"></i>
      </b-link>
    </div>
    <b-container
    class="mt-3 mt-md-6 main-container">
        <div style="max-width: 420px; margin: 0 auto;">
                <div class="ev-logo" >
                    <b-img
                    :src="require('@/assets/img/horizontal-examview-logo.svg')"
                    alt="Examview Premium">
                    </b-img>
                </div>
        </div>
        <b-row>
            <b-col md="9"
            style="padding: 0 20px;">
              <div>
                <h1 class="noselect">
                {{ $t('mode-selector.hello') }}
                </h1>
                <h2 class="noselect">
                {{ $t('mode-selector.text') }}
                </h2>
              </div>
            </b-col>
        </b-row>
        </b-container>
        <!-- Selectors -->
        <b-container
        style="max-width: 1000px;">
        <b-row>
            <b-col sm="12" md="6">
                <div class="mode-selector-container">
                    <div class="mode-selector-text-content" aria-labelledby="description">
                      <p id="description">
                          {{ $t('mode-selector.online-test.description') }}
                      </p>
                      <b-button
                      @click="$emit('mode-changed', mode.ONLINE_TEST)"
                      class="outlined-button">{{ $t('common.online-test') }}</b-button>
                    </div>
                    <img class="mode-selector-img"
                    :src="require('@/assets/img/test-online-bg.jpg')"
                    alt="Test online">
                </div>
              <!--<b-link
              @click="$emit('mode-changed', mode.ONLINE_TEST)">
                <b-card
                overlay
                :img-src="require('@/assets/img/test-online-bg.jpg')"
                text-variant="white"
                >
                    <b-card-text>
                        <div>
                            <h3 class="noselect">
                            {{ $t('common.online-test') }}
                            </h3>
                            <p class="noselect">
                            {{ $t('mode-selector.online-test.description') }}
                            </p>
                        </div>
                    </b-card-text>
                </b-card>
              </b-link>-->
            </b-col>
            <b-col sm="12" md="6">
                 <div class="mode-selector-container">
                    <div class="mode-selector-text-content" aria-labelledby="sessiondescription">
                      <p id="sessiondescription">
                          {{ $t('mode-selector.polling-session.description') }}
                      </p>
                      <b-button
                      @click="$emit('mode-changed', mode.POLLING_SESSION)"
                      class="outlined-button">{{ $t('common.polling-session') }}</b-button>
                    </div>
                    <img class="mode-selector-img"
                    :src="require('@/assets/img/polling-session-bg.jpg')"
                    alt="Polling Session">
                </div>
              <!--<b-link
              @click="$emit('mode-changed', mode.POLLING_SESSION)">
                <b-card
                overlay
                :img-src="require('@/assets/img/polling-session-bg.jpg')"
                alt="Polling Session"
                text-variant="white">
                    <b-card-text>
                        <div>
                            <h3 class="noselect">
                            {{ $t('common.polling-session') }}
                            </h3>
                            <p class="noselect">
                            {{ $t('mode-selector.polling-session.description') }}
                            </p>
                        </div>
                    </b-card-text>
                </b-card>
                </b-link>-->
            </b-col>
        </b-row>
    </b-container>
    <div
    class="version-text">
      <span class="noselect">{{ $store.state.deviceId }}</span>
      <span class="noselect">{{ $store.state.version }}</span>
    </div>
    <!-- DOWNLOAD INSTALLER CARD -->
		<DownloadInstallerCard
		ref="di"
		@show-other-platforms="showDownloadOtherInstallersModal = true" />
    <!-- DOWNLOAD OTHER INSTALLERS MODAL -->
		<b-modal
		id="download-other-installers-modal"
		v-model="showDownloadOtherInstallersModal"
		hide-footer
		scrollable
		size="lg">
			<div class="d-flex">
				<h2 class="mr-2">{{ $t('download-installer.get') }}</h2>
				<b-img class="mt-1" src="img/horizontal-examview-logo.svg"
				width="180" alt="Examview Student"></b-img>
			</div>
      <b-container class="mt-4">
				<h3>{{ $t('download-installer.for-desktop') }}</h3>
				<span>{{ $t('download-installer.recommendation') }}</span>
				<b-row class="pl-5">
					<b-col>
						<div class="mt-4">
							<i class="fa-brands fa-apple fa-2xl mr-2"></i>
							<span style="font-weight: 600; font-size: 0.875rem;">
								{{ $t('download-installer.download-for') }} MacOS
							</span>
						</div>
						<b-link
						:href="getUrl('macos')"
						class="ml-5 mt-3 outlined-button">
							{{ $t('download-installer.download') }}
						</b-link>
						<!--<b-button class="ml-5 mt-3 outlined-button">
							{{ $t('download-installer.download') }}
						</b-button>-->
					</b-col>
					<b-col>
						<div class="mt-4">
							<i class="fa-brands fa-windows fa-2xl mr-2"></i>
							<span style="font-weight: 600; font-size: 0.875rem;">
								{{ $t('download-installer.download-for') }} Windows
							</span>
						</div>
						<b-link
						:href="getUrl('windows')"
						class="ml-5 mt-3 outlined-button">
							{{ $t('download-installer.download') }}
						</b-link>
						<!--<b-button class="ml-5 mt-3 outlined-button">
							{{ $t('download-installer.download') }}
						</b-button>-->
					</b-col>
				</b-row>
      </b-container>
			<b-container class="mt-4 mb-4">
				<h3>{{ $t('download-installer.for-mobile') }}</h3>
				<span>{{ $t('download-installer.recommendation-7-inch') }}</span>
				<b-row class="pl-5">
					<b-col>
						<div class="mt-4">
							<b-img class="mr-2"
							src="img/ios-icon.svg" width="28"
							alt="Examview Student for iOS"></b-img>
							<span style="font-weight: 600; font-size: 0.875rem;">
							{{ $t('download-installer.download-for') }} iOS
							</span>
						</div>
						<b-link
						:href="getUrl('ios')"
						target="_blank"
						class="ml-4">
							<b-img
                            alt=""
                             :aria-label= "$t('download-installer.download-for-ios')"
                             src="img/apple-badge.svg"
                              height="40"></b-img>
						</b-link>
					</b-col>
					<b-col>
						<div class="mt-4">
							<b-img class="mr-2"
							src="img/android-icon.svg" width="28"
							alt="Examview Student for Android"></b-img>
							<span style="font-weight: 600; font-size: 0.875rem;">
								{{ $t('download-installer.download-for') }} Android
							</span>
						</div>
						<b-link
						:href="getUrl('android')"
						target="_blank"
						class="ml-4">
							<b-img
                            alt=""
                            :aria-label= "$t('download-installer.download-for-android')"
								src="img/android-badge.svg" height="40"></b-img>
						</b-link>
					</b-col>
				</b-row>
			</b-container>
    </b-modal>
  </div>
</template>

<script>
import downloadLinks from '@/download-links';
import BaseComponent from '@/components/common/BaseComponent';
import DownloadInstallerCard from '@/components/DownloadInstallerCard.vue';

export default {
	name: 'ModeSelector',
	mixins: [BaseComponent],
	components: {
		DownloadInstallerCard
	},
	data() {
		return {
			showDownloadOtherInstallersModal: false,
		};
	},
	methods: {
		reset() {
			this.$refs.di.mustBeShown();
		},
		getUrl(os) {
			let url = '#';

			switch (os) {
			case 'macos':
				url = downloadLinks.macos;
				break;
			case 'windows':
				url = downloadLinks.windows;
				break;
			case 'ios':
				url = downloadLinks.ios;
				break;
			case 'android':
				url = downloadLinks.android;
				break;
			default:
				break;
			}

			return url;
		},
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
    font-size: 2.1rem;
    font-weight: 700;
}
h3 {
    width: 40%;
    font-weight: bold;
}
.card a {
    text-decoration: underline;
    padding: 0;
    display: inline-flex;
}
.card a:hover {
    text-decoration: none;
}
.card {
    max-width: 470px;
    margin: 0 auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border: 1px solid #E5E5E5;
}
.card-img {
    border-radius: 10px;
    opacity: 0.9;
}
.card-text {
    display: flex;
    justify-content: center;
    align-items: center;
}
.card p {
    font-size: 0.75rem;
    font-weight: 400;
}
.close {
    float: right;
    font-size: 1.7rem !important;
    font-weight: 700 !important;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    background-color: transparent !important;
    border-radius: 0;
    padding: 0;
    position: relative;
    top: -10px;
    left: 5px;
}
.downloader-card {
    animation-name: downloader;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    opacity: 1;
}
@keyframes downloader {
  from {top: 250px; opacity: 0;}
  to {top: 30px; opacity: 1;}
}
.downloader-card-phone {
    position: absolute;
    width: 100%;
    max-width: 100%;
    z-index: 9;
    top: 0;
    animation-name: downloader-app;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    opacity: 1;
}
@keyframes downloader-app {
  from {right: 100%; opacity: 0;}
  to {right: 0%; opacity: 1;}
}
p.downloader-text {
    color: #5E5E5E;
    font-size: 0.875rem;
}
.other-downloader-link {
    display: block !important;
    color: #707070;
    font-weight: 400;
    font-size: 0.875rem;
}
@media only screen and (max-width: 1365px),
only screen and (max-height: 820px) and (-webkit-max-device-pixel-ratio: 2) {
    .downloader-card {
       height: 172px;
       position: absolute;
       bottom: 34px;
       margin: 0 auto;
       left: 10%;
       right: 10%;
       animation-name: downloader-phone;
       animation-duration: 2s;
       animation-fill-mode: forwards;
       opacity: 1;
    }
    @keyframes downloader-phone {
      from {bottom: -200px; opacity: 0;}
      to {bottom: 20px; opacity: 1;}
    }
}
@media only screen and (max-width: 360px) {
    h3 {
        width: 100%;
    }
    .card-body {
        padding: 1.2rem 1rem;
    }
}
.mode-selector-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.mode-selector-text-content {
    height: 190px;
    /*padding-left: 180px;*/
    background-color: #FFFFFF;
    position: relative;
    left: 60px;
    border-radius: 0 100px 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 20px;
}
.mode-selector-text-content p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.mode-selector-img {
    width: 210px;
    height: 210px;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
}
@media only screen and (max-width: 576px) {
    .mode-selector-text-content p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        margin-left: -60px;
    }
    .mode-selector-img {
        width: 180px;
        height: 180px;
    }
    .outlined-button {
        margin-left: -75px;
    }
}
</style>
