<template>
  <div>
    <div class="help-container">
      <b-link
			:href="$store.state.helpUrl"
            :aria-label= "$t('tooltip.help')"
            target="_blank"
			v-b-tooltip.hover="{ title: $t('tooltip.help'), disabled: isMobile() }">
        <i class="fa-solid fa-circle-question"></i>
      </b-link>
    </div>
    <b-container class="mt-3 mt-md-6  main-container">
      <div style="max-width: 420px; margin: 0 auto;">
        <div class="ev-logo">
          <b-img src="img/horizontal-examview-logo.svg" alt="Examview Student"/>
        </div>
      </div>
      <div style="max-width: 420px; margin: 0 auto;">
        <h2 class="noselect">{{ $t('polling-session.title') }}</h2>
        <b-card>
          <div style="padding: 1em;">
						<b-form id="joinForm" @submit.stop.prevent="connect()">
							<div class="step-title-container mt-2">
								<span class="step-title-circle noselect">1</span>
								<h3 class="noselect">{{ $t('common.student-id') }}</h3>
							</div>
							<div style="padding: 0 15px;">
									<b-form-input
									ref="studentIdInput"
									v-model="studentId"
									:placeholder="$t('polling-session.step-1.placeholder')"
									:disabled="showConnectingMessage"
									autocomplete="off"
									@input="showStudentIdError = false"
									:class="showStudentIdError ? 'error' : ''"/>
								<b-form-invalid-feedback :force-show="showStudentIdError">
									{{ $t('common.invalid-student-id') }}
								</b-form-invalid-feedback>
							</div>
							<div class="step-title-container">
								<span class="step-title-circle noselect">2</span>
								<h3 class="noselect">{{ $t('common.session-id') }}</h3>
							</div>
							<div style="padding: 0 15px;">
								<div class="d-flex" style="align-items: center;">
									<b-form-input
									v-model="sessionId"
									:placeholder="$t('polling-session.step-2.placeholder')"
									:disabled="showConnectingMessage"
									autocomplete="off"
									@input="showSessionIdError = false"
									:class="showSessionIdError ? 'error' : ''"/>
									<b-button class="circular-input-button" type="submit" form="joinForm"
                                    :aria-label= "$t('tooltip.log-in')"
									v-b-tooltip.hover="{ title: $t('tooltip.log-in'), disabled: isMobile() }">
										<i v-if="!showConnectingMessage" class="fa-solid fa-arrow-right-to-bracket"></i>
										<i v-else class="fa-solid fa-circle-notch fa-spin"></i>
									</b-button>
								</div>
                                <div role="alert">
								<b-form-invalid-feedback  :force-show="showSessionIdError"
                                  aria-live="assertive">
									"{{ errorMessage }}"
								</b-form-invalid-feedback>
                                </div>
								<b-form-valid-feedback :force-show="showConnectingMessage">
									<p
									class="my-1">
										<span
										style="color: #707070;">
											{{ $t('polling-session.connecting') }}
										</span>
										<b-link
										style="display: inline;"
										@click="showConnectingMessage = false">
											{{ $t('common.buttons.cancel') }}
										</b-link>
									</p>
								</b-form-valid-feedback>
								<b-form-checkbox
								class="mt-4"
								v-model="rememberInfo"
								@change="doRememberInfo()">
									{{ $t('common.remember-info') }}
								</b-form-checkbox>
							</div>
						</b-form>
          </div>
					<b-card-footer>
						<b-link class="pl-1" @click="$emit('back')">
							{{ $t('common.back') }}
						</b-link>
					</b-card-footer>
        </b-card>
			</div>
		</b-container>

		<!-- PASSWORD Modal -->
		<b-modal
		id="session-password-modal"
		v-model="showPasswordModal"
        :header-close-label="$t('common.buttons.close')"
		:title="$t('polling-session.session-protected')"
		@shown="setPasswordModalInputFocus()">
			<b-form id="passwordForm" @submit.stop.prevent="checkPasswordSession()">
				<p class="mb-3 noselect">{{ $t('polling-session.enter-provided-password') }}</p>
				<b-form-input
				ref="passwordInput"
				v-model="sessionPassword"
				:placeholder="$t('polling-session.enter-password')"
				autocomplete="off"
				@input="showPasswordError = false"
				:class="showPasswordError ? 'error' : ''"/>
                <div role="alert">
				<b-form-invalid-feedback aria-live="assertive" :force-show="showPasswordError">
					{{ $t('polling-session.wrong-password') }}
				</b-form-invalid-feedback>
                </div>
			</b-form>
			<template #modal-footer>
				<b-btn @click="showPasswordModal = false">
					{{ $t('common.buttons.cancel') }}
				</b-btn>
				<b-btn variant="primary" type="submit" form="passwordForm"
					:disabled="!sessionPassword || sessionPassword.trim().length === 0">
					{{ $t('common.buttons.ok') }}
				</b-btn>
			</template>
		</b-modal>

    <div
		class="version-text">
      <span class="noselect">{{ $store.state.deviceId }}</span>
      <span class="noselect">{{ $store.state.version }}</span>
    </div>
  </div>
</template>

<script>
import native from '@/api/native';
import app2app from '@/api/app2app';
import { NetworkQuality } from '@/components/common/constants';
import BaseComponent from '@/components/common/BaseComponent';
import PollingSessionInfo from '@/model/PollingSessionInfo';
import ProtectedSessionInfo from '@/model/ProtectedSessionInfo';
import a2aUtils from '@/model/A2AUtils';

export default {
	name: 'PollingSession',
	mixins: [BaseComponent],
	data() {
		return {
			studentId: null,
			sessionId: null,
			rememberInfo: false,
			sessionInfo: null,
			protectedSessionInfo: null,
			showPasswordModal: false,
			sessionPassword: null,
			errorMessage: null,
			showPasswordError: false,
			showStudentIdError: false,
			showSessionIdError: false,
			showConnectingMessage: false,
			isApp2AppMessagesRegistered: false,
			networkQualityStatus: { level: NetworkQuality.OK_QUALITY, errors: 0 },
		};
	},
	methods: {
		unregisterApp2AppMessages() {
			if (!this.isApp2AppMessagesRegistered) {
				return;
			}

			this.isApp2AppMessagesRegistered = false;

			this.$log.debug('PollingSession - *** unregisterApp2AppMessages()');

			app2app.getEventBus().$off('A2AConnectMessage');
			app2app.getEventBus().$off('A2AMessageInfo');
			app2app.getEventBus().$off('A2ANetworkQualityStatus');
		},
		registerApp2AppMessages() {
			if (this.isApp2AppMessagesRegistered) {
				return;
			}

			this.isApp2AppMessagesRegistered = true;

			this.$log.debug('PollingSession - *** registerApp2AppMessages()');

			app2app.getEventBus().$on('A2AConnectMessage', (message) => {
				this.$log.debug(`A2AConnectMessage - ${message.message}`);
			});

			app2app.getEventBus().$on('A2AMessageInfo', () => {
				let message = app2app.getPendingMessage();
				while (message && this.processMessage(message)) {
					message = app2app.getPendingMessage();
				}
			});

			app2app.getEventBus().$on('A2ANetworkQualityStatus', (quality) => {
				this.processNetworkQualityStatus(quality);
			});
		},
		processNetworkQualityStatus(quality) {
			if (this.networkQualityStatus.level === quality.level) {
				return;
			}

			this.$log.debug(`*** A2ANetworkQualityStatus --> level: '${quality.level}' - errors: ${quality.errors}`);

			this.networkQualityStatus = quality;

			switch (quality.level) {
			case NetworkQuality.WARNING_QUALITY:
				if (!this.showConnectingMessage) {
					return;
				}
				this.errorMessage = this.$t('polling-session.connection-failed');
				this.showConnectingMessage = false;
				this.showSessionIdError = true;
				break;
			default:
				break;
			}
		},
		processMessage(message) {
			this.$log.debug(`PollingSession - A2AMessageInfo - ${message.sourceUserId} --> ${message.messageType} : ${a2aUtils.safeDecode(message.messageContent)}`);

			let shouldContinue = true;

			switch (message.messageType) {
			case 'MASTER':
				this.sessionInfo = new PollingSessionInfo(message.messageContent);
				this.$store.commit('setSessionId', message.sourceUserId);
				this.$store.commit('setSessionInfo', this.sessionInfo);
				this.$log.debug(`session info --> ${JSON.stringify(this.sessionInfo)}`);
				this.post('JOINTABLET', this.studentId);
				break;
			case 'PMASTER2':
				this.protectedSessionInfo = new ProtectedSessionInfo(message.messageContent);
				this.sessionInfo = new PollingSessionInfo(null);
				this.sessionInfo.appId = this.protectedSessionInfo.appId;
				this.sessionInfo.supportsAnswerConfirmation = message.messageContent !== 'onfly';
				this.$log.debug(`session info --> ${JSON.stringify(this.sessionInfo)}`);
				this.$store.commit('setSessionId', message.sourceUserId);
				this.$store.commit('setSessionInfo', this.sessionInfo);
				this.sessionPassword = null;
				this.showPasswordError = false;
				this.showConnectingMessage = false;
				this.showPasswordModal = true;
				break;
			case 'WELCOME':
				this.$store.commit('setStudentId', this.studentId);
				this.$store.commit('setStudentInfo', a2aUtils.safeDecode(message.messageContent));
				this.saveInfo();
				this.showConnectingMessage = false;
				this.$router.push({ name: 'Main' });
				shouldContinue = false;
				break;
			case 'JOINERROR':
				this.errorMessage = a2aUtils.safeDecode(message.messageContent);
				this.showSessionIdError = true;
				this.showConnectingMessage = false;
				break;
			default:
				break;
			}

			return shouldContinue;
		},
		reset() {
			this.rememberInfo = native.getItem('remember-info') === 'true';
			if (this.rememberInfo) {
				this.studentId = native.getItem('studentId');
				this.sessionId = native.getItem('sessionId');
			} else {
				native.removeItem('studentId');
				native.removeItem('sessionId');
				this.studentId = null;
				this.sessionId = null;
			}
			this.showStudentIdError = false;
			this.showSessionIdError = false;
			this.showConnectingMessage = false;

			this.$nextTick(() => {
				if (!this.isMobile()) {
					this.$refs.studentIdInput.$el.focus();
				}
			});
		},
		setPasswordModalInputFocus() {
			if (!this.isMobile()) {
				this.$refs.passwordInput.$el.focus();
			}
		},
		connect() {
			if (this.showConnectingMessage || !this.validate()) {
				return;
			}

			this.registerApp2AppMessages();

			this.showConnectingMessage = true;

			app2app.connect(this.sessionId, this.$store.state.deviceId, (res) => {
				this.$log.debug(`connected: ${res.rsok}`);

				if (!res.rsok) {
					this.showConnectingMessage = false;
					this.errorMessage = this.$t('polling-session.connection-failed');
					this.showSessionIdError = true;
				}
			}, (error) => {
				this.$log.error(error);
				this.showConnectingMessage = false;
				this.errorMessage = this.$t('polling-session.connection-failed');
				this.showSessionIdError = true;
			});

			const timeoutId = setTimeout(() => {
				clearTimeout(timeoutId);
				if (!this.showConnectingMessage) {
					return;
				}
				this.showConnectingMessage = false;
				this.errorMessage = this.$t('polling-session.connection-failed');
				this.showSessionIdError = true;
			}, 45000);
		},
		post(type, content) {
			const umid = btoa(Math.random()).slice(0, 10); // generate random id
			app2app.postMessage(this.$store.state.sessionId, type, content, umid, (res) => {
				this.$log.debug(res);
			}, (error) => {
				this.$log.error(error);
			});
		},
		validate() {
			this.showStudentIdError = !(this.studentId && this.studentId.trim().length > 0);
			this.showSessionIdError = !(this.sessionId && (/^[0-9]*$/.test(this.sessionId)
				|| /^\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b$/.test(this.sessionId)));

			this.errorMessage = null;

			if (this.showSessionIdError) {
				this.errorMessage = this.$t('polling-session.invalid-session-id');
			}

			return !this.showStudentIdError && !this.showSessionIdError;
		},
		doRememberInfo() {
			native.setItem('remember-info', this.rememberInfo);
		},
		saveInfo() {
			if (this.rememberInfo) {
				native.setItem('studentId', this.studentId.toUpperCase());
				native.setItem('sessionId', this.sessionId);
			} else {
				native.removeItem('studentId');
				native.removeItem('sessionId');
			}
		},
		checkPasswordSession() {
			this.showPasswordError = this.sessionPassword !== this.protectedSessionInfo.pass;

			if (this.showPasswordError) {
				return;
			}

			this.showPasswordModal = false;
			this.showConnectingMessage = true;

			this.post('JOIN2', this.studentId);
		},
		handleBackButton() {
			if (this.showPasswordModal) {
				this.showPasswordModal = false;
				this.$log.debug('showPasswordModal is open!');
				return true;
			}
			this.$log.debug('No modal is open!');
			return false;
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    color: #D5006C;
}
h1 {
  margin: 40px 0 0;
  color: #D5006C;
}
.card {
    margin: 15px auto;
    min-height: auto;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    z-index: 2;
}
.card-body {
    padding: 0;
}
.card-text {
    padding: 1em;
}
.card-footer {
    padding: 0.25rem 1.25rem;
    background-color: #FFFFFF;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0 0 10px 10px !important;
}
.modal-title {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 5px;
 }
.form-control {
    font-size: 0.875rem;
    height: 42px;
}
.form-control.error {
	border-color: #FF5252;
}
</style>
