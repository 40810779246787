<template>
  <div>
		<b-container
    fluid
		class="mb-0 mt-2 mt-sm-0">
      <b-row>
        <b-col cols="12" class="mb-2 mt-2">
          <div
          :class="'d-flex question-title-container '
						+ (smaMode ? 'sidebar-margin' : '')">
						<h3 class="noselect"
                        style="font-weight: bold;">
							{{ definition ? definition.questionName : '?' }}
						</h3>
						<span class="noselect"
                        style="color: #D5006C; float: right;">
							{{ definition ? getQuestionType(definition.questionType) : '?' }}
						</span>
					</div>
        </b-col>
        <b-col cols="12">
          <b-card
          :class="smaMode ? 'sidebar-margin fixNotchMarginTop fixNotchMarginBottom' :
                            'fixNotchMarginTop fixNotchMarginBottom'">
            <b-card-text>
							<div role="main" aria-live="assertive"
							v-html="definition ? definition.questionContent : ''"
							class="html-question mx-3 pt-2 pb-0"/>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
		</b-container>

		<ExternalContent ref='externalContent'/>
  </div>
</template>

<script>
import native from '@/api/native';
import a2aUtils from '@/model/A2AUtils';
import BaseComponent from '@/components/common/BaseComponent';
import ExternalContent from '@/components/ExternalContent.vue';

export default {
	name: 'Question',
	mixins: [BaseComponent],
	props: ['qdef'],
	components: {
		ExternalContent
	},
	data() {
		return {
			definition: null,
		};
	},
	mounted() {
		const self = this;
		window.qdef_mobile_link = (link) => { self.openLink(self, link); };
	},
	computed: {
		smaMode() {
			return this.$store.state.smaMode;
		}
	},
	methods: {
		isExternalContentModalOpen() {
			return this.$refs.externalContent.isModalOpen();
		},
		hideExternalContentModal() {
			this.$refs.externalContent.hideModal();
		},
		openLink(vm, link) {
			console.log(link);
			vm.$refs.externalContent.showContent(link);
		},
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		qdef(newVal, oldVal) {
			this.definition = newVal;
			if (native.isMobile()) {
				this.definition.questionContent = a2aUtils.replaceLinks(this.definition.questionContent,
					'window.qdef_mobile_link');
			}
		}
	},
};
</script>

<style scoped>
h2 {
    color: #D5006C;
    font-weight: 600;
}
h4 {
    font-size: 1.375rem;
    font-weight: 600;
    color: #707070;
}
h3 {
    margin-bottom: 0;
}
.card {
    padding: 0.5rem;
    padding-right: 0;
    top: 0px;
    margin-bottom: 10px;
    height: 590px;
}
@media only screen and (max-width: 1365px),
		only screen and (max-height: 820px) {
    .card {
    bottom: 70px;
    padding: 0.5rem;
    padding-right: 0;
    position: fixed;
    left: 15px;
    right: 15px;
    top: 105px;
    margin-bottom: 10px;
    height: auto;
    max-width: 100%;
    margin: 0 auto;
    }
}
@media only screen and (max-width: 576px) {
    .card {
        bottom: 80px;
        top: 115px;
    }
}
.card-body {
    margin: 0;
    padding: 0 0 8px 0;
    overflow: auto;
}
.question-title-container {
    align-items: center;
    justify-content: space-between;
}
@media only screen and (max-width: 1365px),
		only screen and (max-height: 820px) {
    .question-title-container span {
        position: fixed;
        right: 15px;
    }
}
@media only screen and (max-width: 576px) {
    .question-title-container {
        display: none !important;
    }
}
</style>
